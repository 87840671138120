import React, { useState, useEffect } from 'react';
import './Admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faArrowUp, faEye, faRedo } from '@fortawesome/free-solid-svg-icons';
import SocketComponent, { getSocket } from './Socket';

const Admin = () => {
  const [players, setPlayers] = useState([]);
  const [enableIndice, setEnableIndice] = useState(false);
  const [enablePower, setEnablePower] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [category, setCategory] = useState('');
  const [indice, setIndice] = useState('');
  const [isNextQuestionDisabled, setIsNextQuestionDisabled] = useState(true);

  useEffect(() => {
    fetchConnectedPlayers();
    fetchGameSettings();

    const socket = getSocket();
    if (socket) {
      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'updatePlayer') {
          fetchConnectedPlayers();
        }
      };
    }

    return () => {
      if (socket) {
        socket.onmessage = null; // Cleanup listener on unmount
      }
    };
  }, []);

useEffect(() => {
    fetchConnectedPlayers();
    fetchGameSettings();

    const socket = getSocket();
    if (socket) {
        socket.onmessage = async (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'updatePlayer') {
                fetchConnectedPlayers();
            } else if (message.type === 'response_sent') {
                fetchConnectedPlayers();          
            } else if (message.type === 'power_use') {
                fetchConnectedPlayers();          
            } else if (message.type === 'indice_use') {
                fetchConnectedPlayers();          
            }
        };
    }

    return () => {
        if (socket) {
            socket.onmessage = null; // Cleanup listener on unmount
        }
    };
}, []);

  const fetchConnectedPlayers = async () => {
    try {
      const res = await fetch('http://www.academiagames.fr:5000/api/connected_users');
      const data = await res.json();
      setPlayers(data.users);
      setEnableIndice(data.enableIndice)
      setEnablePower(data.enablePower)
      setCategoryName(data.categoryName)
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs connecté', error);
    }
  };

const fetchPlayerData = async (pseudo) => {
    try {
        const res = await fetch(`http://www.academiagames.fr:5000/api/get_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pseudo }),
        });

        if (res.ok) {
            return await res.json();
        } else {
            console.error('Erreur lors de la récupération de l\'utilisateur');
        }
    } catch (error) {
        console.error('Erreur lors de la récupération de l\'utilisateur', error);
    }
};
  const fetchGameSettings = async () => {
    // Implementation to fetch game settings (enableIndice and enablePower)
  };

  const handleToggleIndice = async () => {
    try {
      const res = await fetch('http://www.academiagames.fr:5000/api/toggle_indice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await res.json();
      setEnableIndice(data.enableIndice);
    } catch (error) {
      console.error('Erreur lors du basculement de enableIndice', error);
    }
  };

  const handleTogglePower = async () => {
    try {
      const res = await fetch('http://www.academiagames.fr:5000/api/toggle_power', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await res.json();
      setEnablePower(data.enablePower);
    } catch (error) {
      console.error('Erreur lors du basculement de enablePower', error);
    }
  };

  const handleGivePower = async (player, powerType) => {
    try {
      await fetch('http://www.academiagames.fr:5000/api/give_power', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pseudo: player.pseudo, powerType }),
      });
      fetchConnectedPlayers(); // Refresh player data to reflect the new power levels
    } catch (error) {
      console.error('Erreur lors de l\'attribution du pouvoir', error);
    }
  };

  const handleAction = async (player, actionType) => {
    let points;
    if (actionType === 'use-boost') {
      try {
        await fetch('http://www.academiagames.fr:5000/api/use_boost', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pseudo: player.pseudo }),
        });
        setPlayers(players.map(p =>
          p.pseudo === player.pseudo ? { ...p, haveActiveBoostPower: false } : p
        ));
      } catch (error) {
        console.error('Erreur lors de l\'utilisation du boost', error);
      }
      return;
    } else if (actionType === 'use-second-chance') {
      try {
        await fetch('http://www.academiagames.fr:5000/api/use_second_chance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pseudo: player.pseudo }),
        });
        setPlayers(players.map(p =>
          p.pseudo === player.pseudo ? { ...p, haveActiveSecondChancePower: false } : p
        ));
      } catch (error) {
        console.error('Erreur lors de l\'utilisation de la seconde chance', error);
      }
      return;
    } else if (actionType === '2-point') {
      points = 2.0;
    } else if (actionType === '1-point') {
      points = player.haveActiveBoostPower ? 2.0 : 1.0;
    } else if (actionType === '0.5-point') {
      points = player.haveActiveBoostPower ? 1.0 : 0.5;
    } else if (actionType === 'unlock') {
      if (!player.haveActiveSecondChancePower) {
        return; // Prevent unlocking if the second chance power is not active
      }
      try {
        await fetch('http://www.academiagames.fr:5000/api/unlock', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pseudo: player.pseudo }),
        });
        setPlayers(players.map(p =>
          p.pseudo === player.pseudo ? { ...p, alreadyUnlock: true, response: '' } : p
        ));
      } catch (error) {
        console.error('Erreur lors du déblocage de la réponse', error);
      }
      return;
    }

    if (points) {
      try {
        await fetch('http://www.academiagames.fr:5000/api/give_point', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pseudo: player.pseudo, points }),
        });
        setPlayers(players.map(p =>
          p.pseudo === player.pseudo ? { ...p, alreadyPoint: true } : p
        ));
      } catch (error) {
        console.error('Erreur lors de l\'attribution des points', error);
      }
    }
  };

  const handleNextQuestion = async () => {
    if (category && indice) {
      try {
        await fetch('http://www.academiagames.fr:5000/api/next_question', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ categoryName: category, indice }),
        });
        console.log('Next question set with category:', category, 'and indice:', indice);
        // Optionally, refresh data or notify users about the new question
      } catch (error) {
        console.error('Erreur lors de la configuration de la prochaine question', error);
      }
       fetchConnectedPlayers();

    }
  };

  return (
    <div className="admin-container">
      <SocketComponent pseudo="Admin" />

      <table className="admin-table">
        <thead>
          <tr>
            <th>Pseudo</th>
            <th>Réponse</th>
            <th>Fil rouge</th>
            <th>1 Point</th>
            <th>0.5 Point</th>
            <th>Débloqué</th>
            <th>Boost</th>
            <th>Spy</th>
            <th>Chance</th>
            <th>Indice</th>
          </tr>
        </thead>
        <tbody>
          {players.map(player => (
            <tr key={player.pseudo}>
              <td>{player.pseudo}</td>
              <td>{player.response}</td>
              <td>
                <button onClick={() => handleAction(player, '2-point')} disabled={categoryName != "Fil Rouge" || player.alreadyPoint}>
                  Fil rouge
                </button>
              </td>
              <td>
                <button onClick={() => handleAction(player, '1-point')} disabled={player.alreadyPoint}>
                  1 Point
                </button>
              </td>
              <td>
                <button onClick={() => handleAction(player, '0.5-point')} disabled={player.alreadyPoint}>
                  0.5 Point
                </button>
              </td>
              <td>
                <button onClick={() => handleAction(player, 'unlock')}
                  disabled={!player.haveActiveSecondChancePower || !player.response || player.haveActiveSecondChancePower && player.alreadyUnlock}>
                  Débloqué
                </button>
              </td>
              <td>
                <FontAwesomeIcon 
                  icon={faArrowUp} 
                  className={player.haveActiveBoostPower ? 'icon-used' : ''} 
                  onClick={() => handleGivePower(player, 'boostPower')} 
                />
              </td>
              <td>
                <FontAwesomeIcon 
                  icon={faEye} 
                  className={player.haveActiveSpy ? 'icon-used' : ''} 
                  onClick={() => handleGivePower(player, 'spyPower')} 
                />
              </td>
              <td>
                <FontAwesomeIcon 
                  icon={faRedo} 
                  className={player.haveActiveSecondChancePower ? 'icon-used' : ''} 
                  onClick={() => handleGivePower(player, 'secondChancePower')} 
                />
              </td>
              <td>
                <FontAwesomeIcon 
                  icon={faLightbulb} 
                  className={player.haveActiveIndice ? 'icon-used' : ''} 
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="admin-actions">
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="category-dropdown"
        >
          <option value="">Sélectionnez une catégorie</option>
          <option value="Animé & Dessin Animé">Animé & Dessin Animé</option>
          <option value="Jeux Vidéo">Jeux Vidéo</option>
          <option value="Film">Film</option>
          <option value="Série">Série</option>
          <option value="Musique Internationale">Musique Internationale</option>
          <option value="Musique Française">Musique Française</option>
          <option value="Groupe & Comédie Musicale">Groupe & Comédie Musicale</option>
          <option value="Studio d'Animation">Studio d'Animation</option>
          <option value="Fil Rouge">Fil Rouge</option>
        </select>
        <input
          type="text"
          placeholder="Indice"
          value={indice}
          onChange={(e) => setIndice(e.target.value)}
          className="indice-input"
        />
        <button onClick={handleNextQuestion}>
          Prochaine question
        </button>
        <button onClick={handleToggleIndice}>
          {enableIndice ? 'Bloquer Indice' : 'Activer Indice'}
        </button>
        <button onClick={handleTogglePower}>
          {enablePower ? 'Bloquer Pouvoir' : 'Activer Pouvoir'}
        </button>
      </div>
    </div>
  );
};

export default Admin;
