import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faArrowUp, faEye, faRedo } from '@fortawesome/free-solid-svg-icons';
import './Game.css';
import SocketComponent, { getSocket } from './Socket';

const Game = () => {
  const [response, setResponse] = useState('');
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState({
    response: '',
    indice: 0,
    boostPower: 0,
    spyPower: 0,
    secondChancePower: 0,
    haveActiveIndice: false,
    haveActiveSpy: false,
    haveActiveBoostPower: false,
    haveActiveSecondChancePower: false,
    enablePower: false,
    enableIndice: false,
    categoryName: '',
  });
  const [usedIndice, setUsedIndice] = useState('');
  const [spyResponse, setSpyResponse] = useState('');
  const [showSpyList, setShowSpyList] = useState(false);
  const navigate = useNavigate();

  const spyList = ['Tony', 'Maxime', 'Owen', 'Laurel', 'Quentin', 'Emilio'];

    useEffect(() => {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        setUser(storedUser);
        fetchUserInfo(storedUser.pseudo);
      } else {
        navigate('/');
      }
    }, [navigate]);

    useEffect(() => {
      const socket = getSocket();
      if (socket && user) {
        socket.onmessage = async (event) => {
          const message = JSON.parse(event.data);
          if (message.type === 'next_question' || message.type === 'unlock') {
            fetchUserInfo(user.pseudo);
          }
        };

        return () => {
          socket.onmessage = null;
        };
      }
    }, [user]);

  const fetchUserInfo = async (pseudo) => {
    try {
      const res = await fetch('http://www.academiagames.fr:5000/api/get_information', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pseudo }),
      });

      if (res.ok) {
        const data = await res.json();
        setUserInfo({
          response: data.response,
          indice: data.indice.low !== undefined ? data.indice.low : data.indice,
          boostPower: data.boostPower.low !== undefined ? data.boostPower.low : data.boostPower,
          spyPower: data.spyPower.low !== undefined ? data.spyPower.low : data.spyPower,
          secondChancePower: data.secondChancePower.low !== undefined ? data.secondChancePower.low : data.secondChancePower,
          haveActiveIndice: data.haveActiveIndice,
          haveActiveSpy: data.haveActiveSpy,
          haveActiveBoostPower: data.haveActiveBoostPower,
          haveActiveSecondChancePower: data.haveActiveSecondChancePower,
          enablePower: data.enablePower,
          enableIndice: data.enableIndice,
          categoryName: data.categoryName,
        });
      } else {
        console.error('Erreur lors de la r?up?ation des informations');
      }
    } catch (error) {
      console.error('Erreur lors de la r?up?ation des informations', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/');
  };

const handleSendResponse = async () => {
    if (user && response.trim() !== '') {
        try {
            const res = await fetch('http://www.academiagames.fr:5000/api/send_response', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pseudo: user.pseudo,
                    response: response.trim(),
                }),
            });

            if (res.ok) {
                const updatedUser = { ...user, response: response.trim() };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                setUser(updatedUser);
                setResponse('');
                fetchUserInfo(user.pseudo);
            } else {
                console.error('Erreur lors de l\'envoi de la r?onse');
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi de la r?onse', error);
        }
    }
};

  const handleUseIndice = async () => {
    if (user) {
      try {
        const res = await fetch('http://www.academiagames.fr:5000/api/use_indice', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pseudo: user.pseudo }),
        });

        if (res.ok) {
          const data = await res.json();
          setUsedIndice(data.indice);
          fetchUserInfo(user.pseudo);
        } else {
          console.error('Erreur lors de l\'utilisation de l\'indice');
        }
      } catch (error) {
        console.error('Erreur lors de l\'utilisation de l\'indice', error);
      }
    }
  };

  const handleSpySelection = async (selectedPseudo) => {
    if (user) {
      try {
        const res = await fetch('http://www.academiagames.fr:5000/api/use_spypower', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pseudo: user.pseudo, targetPseudo: selectedPseudo }),
        });

        if (res.ok) {
          const data = await res.json();
          setSpyResponse(data.response);
          setShowSpyList(false);
          fetchUserInfo(user.pseudo);
        } else {
          console.error('Erreur lors de l\'utilisation du pouvoir de Spy');
        }
      } catch (error) {
        console.error('Erreur lors de l\'utilisation du pouvoir de Spy', error);
      }
    }
  };

const handlePowerClick = async (powerType) => {
    if (user) {
        if (powerType === 'indice' && userInfo.indice > 0) {
            handleUseIndice();
        } else if (powerType === 'spy' && userInfo.spyPower > 0) {
            setShowSpyList(!showSpyList);
        } else if (powerType === 'boostPower' && userInfo.boostPower > 0) {
            try {
                await fetch('http://www.academiagames.fr:5000/api/use_boost', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ pseudo: user.pseudo }),
                });
                fetchUserInfo(user.pseudo); // Refresh user info
            } catch (error) {
                console.error('Error activating boost power:', error);
            }
        } else if (powerType === 'secondChancePower' && userInfo.secondChancePower > 0) {
            try {
                await fetch('http://www.academiagames.fr:5000/api/want_use_chance', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ pseudo: user.pseudo }),
                });
                fetchUserInfo(user.pseudo); // Refresh user info
            } catch (error) {
                console.error('Error activating second chance power:', error);
            }
        }
        console.log(`${powerType} power clicked`);
    }
};
  return (
    <div className="game-container">
      {user && <SocketComponent pseudo={user.pseudo} />}
      <div className="game-header">
        <span className="game-username">{user ? user.pseudo : 'Pseudo'}</span>
        <button className="logout-button" onClick={handleLogout}>
          Déconnexion
        </button>
      </div>

      <div className="category-box">
        {userInfo.categoryName ? userInfo.categoryName : 'Cat?orie'}
      </div>
      
        <div className="info-box">
    <p>Ce qui est demandé pour chaque catégorie :</p>
    <ul>
      <li>Animé/Dessin animé : Le titre (+ 1 point)</li>
      <li>Animation : Le titre (+ 1 point)</li>
      <li>Film : Le titre (+ 1 point)</li>
      <li>Série : Le titre (+ 1 point)</li>
      <li>Jeux/Vidéo : Le titre (+ 1 point)</li>
      <li>Groupe / Comédie Musicale : Le nom du groupe ou comédie musicale (+ 1 point)</li>
      <li>Musique française : Le titre (+ 0.5 point) et l'interprète (+ 0.5 point)</li>
      <li>Musique internationale : Le titre (+ 0.5 point) et l'interprète (+ 0.5 point)</li>
    </ul>
  </div>

      {userInfo.response ? (
        <p className="blocked-message">
          Vous êtes bloqué suite à votre tentative, veuillez attendre le prochain extrait.
        </p>
      ) : (
        <div className="game-content">
          <input
            type="text"
            className="response-input"
            placeholder="Votre réponse..."
            value={response}
            onChange={(e) => setResponse(e.target.value)}
          />
          <button
            className="send-button"
            onClick={handleSendResponse}
            disabled={response.trim() === ''}
          >
            Envoyer
          </button>

          <div className="game-icons">
            {userInfo.enableIndice && (
              <div
                className={`icon-container ${userInfo.indice === 0 || userInfo.haveActiveIndice ? 'disabled' : ''}`}
                onClick={() => userInfo.indice > 0 && !userInfo.haveActiveIndice && handlePowerClick('indice')}
              >
                <FontAwesomeIcon icon={faLightbulb} className="icon" />
                <span>{userInfo.indice}</span>
              </div>
            )}

            {userInfo.enablePower && (
              <>
                <div
                  className={`icon-container ${userInfo.boostPower === 0 || userInfo.haveActiveBoostPower || userInfo.haveActiveSpy || userInfo.haveActiveSecondChancePower ? 'disabled' : ''}`}
                  onClick={() => userInfo.boostPower > 0 && !userInfo.haveActiveBoostPower && !userInfo.haveActiveSpy && !userInfo.haveActiveSecondChancePower && handlePowerClick('boostPower')}
                >
                  <FontAwesomeIcon icon={faArrowUp} className="icon" />
                  <span>{userInfo.boostPower}</span>
                </div>
                <div
                  className={`icon-container ${userInfo.spyPower === 0 || userInfo.haveActiveBoostPower || userInfo.haveActiveSpy || userInfo.haveActiveSecondChancePower ? 'disabled' : ''}`}
                  onClick={() => userInfo.spyPower > 0 && !userInfo.haveActiveBoostPower && !userInfo.haveActiveSpy && !userInfo.haveActiveSecondChancePower && handlePowerClick('spy')}
                >
                  <FontAwesomeIcon icon={faEye} className="icon" />
                  <span>{userInfo.spyPower}</span>
                </div>
                <div
                  className={`icon-container ${userInfo.secondChancePower === 0 || userInfo.haveActiveBoostPower || userInfo.haveActiveSpy || userInfo.haveActiveSecondChancePower ? 'disabled' : ''}`}
                  onClick={() => userInfo.secondChancePower > 0 && !userInfo.haveActiveBoostPower && !userInfo.haveActiveSpy && !userInfo.haveActiveSecondChancePower && handlePowerClick('secondChancePower')}
                >                  <FontAwesomeIcon icon={faRedo} className="icon" />
                  <span>{userInfo.secondChancePower}</span>
                </div>
              </>
            )}
          </div>

          {usedIndice && (
            <p className="used-indice-message">
              Indice utilisé : {usedIndice}
            </p>
          )}

          {spyResponse && (
            <p className="used-spy-message">
              Pouvoir d'espionnage activé : {spyResponse}
            </p>
          )}
          {showSpyList && (
            <div className="spy-list">
              {spyList.map((pseudo) => (
                <div key={pseudo} className="spy-list-item" onClick={() => handleSpySelection(pseudo)}>
                  {pseudo}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Game;
