// Socket.js
import { useEffect } from 'react';

let socket;

const SocketComponent = ({ pseudo }) => {
  useEffect(() => {
    socket = new WebSocket('ws://www.academiagames.fr:5000');

    socket.onopen = () => {
      console.log('Connected to server');
      const loginMessage = JSON.stringify({
        type: 'login',
        pseudo: pseudo,
      });
      socket.send(loginMessage);
    };

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [pseudo]);

  return null;
};

// Function to access the socket instance
export const getSocket = () => socket;

export default SocketComponent;
